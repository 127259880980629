<template>
    <div class="parent-card">
        <b-card :title="$t('g.Parents')" v-if="parentsCardProps">
            <hr />
            <div v-if="parentsCardProps.length">
                <b-row class="align-items-center mb-1" v-for="(parent, index) in parentsCardProps" :key="index">
                    <b-link :to="`/show-parent/${parent.id}`">
                        <b-avatar
                            :src="parent.user.media && parent.user.media.length ? parent.user.media[0].path : profileImgaePlaceholder"
                            class="mx-1" />
                        <span class="user-name">{{ `${parent.user.first_name} ${parent.user.last_name}` }}</span>
                    </b-link>
                </b-row>
            </div>
            <h4 v-else>
                {{ $t('g.profile/parents_card_component/no_parents') }}
            </h4>
        </b-card>
    </div>
</template>
<script>
import { BCard, BRow, BAvatar, BLink } from 'bootstrap-vue'
export default {
    name: 'ProfileParentsCardComponent',
    components: {
        BRow,
        BCard,
        BAvatar,
        BLink
    },
    props: ['parentsCardProps'],
    data() {
        return {
            profileImgaePlaceholder: require('@/assets/images/placeholders/profileAvatar.png'),
        }
    }
}
</script>
<style lang="scss">
@import './index.scss';
</style>